



















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import {
    ChartData, ChartDataSets, ChartOptions, ChartElementsOptions,
} from 'chart.js';
import Day from '@/modules/common/types/day.type';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import GRAPH_TYPES from '@/modules/common/constants/graph-types.constant';
import ParitySettingsService, { ParitySettingsServiceS } from '../../parity-settings.service';
import ParityService, { ParityServiceS } from '../../parity.service';
import BML_COLORS from '../../constants/bml-colors';

@Component({
    components: {
        CustomGraph, LoaderWrapper,
    },
})
export default class SpreadGraph extends Vue {
    @Inject(ParityServiceS) private parityService!: ParityService;
    @Inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;

    private readonly labels: string[] = ['>20%', '10%-20%', '5%-10%', '1%-5%', 'M', '-5%-1%', '-10%-5%', '-20%-10%', '<-20%', 'N/A'];
    private selectedLabelIndexes: number[] = [];

    // onTooltipClick(day: Day) {
    //     this.$router.push({ name: 'cars-rates.graph.day-popup', params: { day: String(day) } });
    // }

    mounted() {
        if (this.paritySettingsService.priceDif && this.paritySettingsService.priceDif.toString() === [[null, -0.2], [-0.2, -0.1]].toString()) {
            this.selectedLabelIndexes = [7, 8];
        }
    }

    get chartData(): ChartData | null {
        const datasets: ChartDataSets[] = [];

        const backgroundColor: string[] = [];
        const { spreadData } = this.parityService;

        if (!spreadData) return null;

        const keys = Object.keys(this.parityService.spreadData as {});

        const data = this.labels.map(label => ({
            y: label,
            x: spreadData[label],
        }));

        for (let i = 0; i < 4; i++) {
            backgroundColor.push(BML_COLORS.YELLOW);
        }
        backgroundColor.push(BML_COLORS.GREEN);
        for (let i = 0; i < 4; i++) {
            backgroundColor.push(BML_COLORS.RED);
        }
        backgroundColor.push(BML_COLORS.GRAY);

        if (this.selectedLabelIndexes.length) {
            this.selectedLabelIndexes.forEach(index => {
                backgroundColor[index] = BML_COLORS.LIGHT_GRAY;
            });
        }

        return {
            labels: this.labels.map(label => {
                if (label === 'N/A') {
                    return 'Sold out';
                }

                return label;
            }),
            datasets: [{
                label: 'BML',
                data,
                backgroundColor,
                minBarLength: 3,
            }],
        };
    }

    get options(): ChartOptions {
        const { spreadData } = this.parityService;

        const maxXAxis = spreadData ? Object.keys(spreadData as any).reduce((acc: number, key: string) => {
            const value = Number(spreadData[key]);

            if (value > acc) {
                return value;
            }

            return acc;
        }, 0) : 0;

        return {
            responsive: true,
            maintainAspectRatio: false,
            onHover: this.handleHover,
            legend: {
                display: false,
            },
            scales: {
                xAxes: [{
                    ticks: {
                        max: Math.round(maxXAxis * 1.2),
                        min: 0,
                        stepSize: Math.round(maxXAxis / 30) * 10,
                        padding: 10,
                    },
                    gridLines: {
                        drawOnChartArea: false,
                        drawTicks: false,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: '# of cases',
                    },
                }],
                yAxes: [{
                    ticks: {
                        padding: 10,
                    },
                    gridLines: {
                        drawOnChartArea: false,
                        drawTicks: false,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Price diff (%)',
                    },
                }],
            },
            onClick: this.handleChartClick,
        };
    }

    handleChartClick(event: MouseEvent, point: {_index: number}[]) {
        if (!point[0]) {
            return;
        }

        const getRanges = (indexes: number[]) => {
            let ranges: [number | null, number | null][] = [];

            indexes.forEach(index => {
                switch (index) {
                    case 0:
                        ranges.push([0.2, null]);
                        break;
                    case 1:
                        ranges.push([0.1, 0.2]);
                        break;
                    case 2:
                        ranges.push([0.05, 0.1]);
                        break;
                    case 3:
                        ranges.push([0.01, 0.05]);
                        break;
                    case 4:
                        ranges.push([-0.01, 0.01]);
                        break;
                    case 5:
                        ranges.push([-0.05, -0.01]);
                        break;
                    case 6:
                        ranges.push([-0.1, -0.05]);
                        break;
                    case 7:
                        ranges.push([-0.2, -0.1]);
                        break;
                    case 8:
                        ranges.push([null, -0.2]);
                        break;
                    case 9:
                        ranges.push([null, null]);
                        break;
                    default:
                        ranges = [[null, null]];
                }
            });

            return ranges;
        };

        // eslint-disable-next-line no-underscore-dangle
        const xIndex = point[0]._index;

        if (event.ctrlKey) {
            const selectedLabel = this.selectedLabelIndexes.find(index => index === xIndex);

            if (!selectedLabel) {
                this.selectedLabelIndexes = this.selectedLabelIndexes.concat([xIndex]);
            } else {
                this.selectedLabelIndexes = this.selectedLabelIndexes.filter(index => index !== selectedLabel);
            }

            this.paritySettingsService.priceDif = getRanges(this.selectedLabelIndexes);
        }

        if (
            !event.ctrlKey
            && this.paritySettingsService.priceDif
            && (this.paritySettingsService.priceDif.length > 1
            || (this.selectedLabelIndexes.find(index => index === xIndex) !== undefined))
        ) {
            this.selectedLabelIndexes = [];
            this.paritySettingsService.priceDif = null;
        } else if (!event.ctrlKey) {
            this.selectedLabelIndexes = [xIndex];
            this.paritySettingsService.priceDif = getRanges(this.selectedLabelIndexes);
        }
    }

    handleHover(event: Event, chartElement: ChartElementsOptions[]) {
        if (!event || !event.target) {
            return;
        }

        const target = event.target as HTMLElement;

        target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    }

    borderColor(companyName: string) {
        return '#333333';
    }

    colorByHotel(companyName: string) {
        return '#555555';
    }
}
